import { render, staticRenderFns } from "./privacyPolicy.vue?vue&type=template&id=3231d5a2"
var script = {}
import style0 from "./privacyPolicy.vue?vue&type=style&index=0&id=3231d5a2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports